import { ready } from "./lib/ready";
import { startStimulusApplication } from "./controllers";

// Import JS files with global side-effects
import "./lib/global_init";
import "jquery-validation";
import "./lib/application_head";
import "./lib/ynab";
import "./lib/analytics";
import "./lib/form_dirty_tracking_all_fields_present";
import "./lib/recurly_subscription_form";
import "./lib/gift_purchases";
import "./lib/group_payment_method";
import "./lib/referral_program";
import "./lib/registrations";
import "./lib/subscription_form_dirty_tracking";
import "./lib/subscriptions";
import "./lib/support_access";
import "./lib/ynab4_upgrade_form";
import "./lib/oauth";
import "./lib/otps";
import "./lib/exit_survey";
import "./lib/form_password_toggle";
import "./lib/kustomer_chat";
import "./lib/confirm_apple_subscription";
import "./lib/impersonation";
import "./lib/invoices";
import "./lib/castle";

ready(() => {
    startStimulusApplication();
});

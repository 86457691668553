import { ready } from "./ready";

ready(() => {
    if (!!window.YNAB_CLIENT_CONSTANTS?.CASTLE_PUBLISHABLE_KEY && typeof window.Castle !== "undefined") {
        initTokenInjectForFormSubmit();
        initTokenInjectForJQueryAjax();
        initTokenInjectForRailsUjs();
    }
});

function initTokenInjectForFormSubmit() {
    $("form[data-castle-inject-token]").on("submit", function (event) {
        window.Castle.injectTokenOnSubmit(event);
    });
}
function initTokenInjectForJQueryAjax() {
    $(document).ajaxSend((_ev, xhr) => {
        setCastleRequestToken(xhr);
    });
}

function initTokenInjectForRailsUjs() {
    document.body.addEventListener("ajax:beforeSend", (event: CustomEvent) => {
        const xhr = event.detail[0];
        setCastleRequestToken(xhr);
    });
}

function setCastleRequestToken(xhr: XMLHttpRequest | JQuery.jqXHR<any>) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    window.Castle.createRequestToken().then((castleRequestToken) => {
        xhr.setRequestHeader("X-Castle-Request-Token", castleRequestToken);
    });
}
